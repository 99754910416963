<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    xml:space="preserve"
    class="u-svg-content"
    viewBox="0 0 512.016 512.016"
    x="0px"
    y="0px"
    id="svg-7d33"
    style="enable-background: new 0 0 512.016 512.016; width:100%; height: 100%;"
  >
    <path
      style="fill: #6f7070"
      d="M511.936,71.336v243.84c0,17.76-14.24,32-32,32H91.456c-17.76,0-32-14.24-32-32V71.336  c0-17.6,14.24-32,32-32h388.48C497.696,39.336,511.936,53.736,511.936,71.336z"
    ></path>
    <rect
      x="59.376"
      y="97.544"
      style="fill: #464646"
      width="452.64"
      height="51.968"
    ></rect>
    <g>
      <path
        style="fill: #787878"
        d="M393.968,52.44H290.704c-8.832,0-16,7.152-16,16s7.168,16,16,16h103.264c8.832,0,16-7.152,16-16   C409.968,59.608,402.8,52.44,393.968,52.44z"
      ></path>
      <path
        style="fill: #787878"
        d="M468.576,52.44h-27.984c-8.832,0-16,7.152-16,16s7.168,16,16,16h27.984c8.832,0,16-7.152,16-16   C484.576,59.608,477.424,52.44,468.576,52.44z"
      ></path>
    </g>
    <g>
      <path
        style="fill: #3e3e3e"
        d="M179.792,107.528h-78.016c-8.832,0-16,7.152-16,16s7.168,16,16,16h78.016c8.832,0,16-7.152,16-16   C195.792,114.68,188.64,107.528,179.792,107.528z"
      ></path>
      <path
        style="fill: #3e3e3e"
        d="M254.416,107.528h-27.968c-8.832,0-16,7.152-16,16s7.168,16,16,16h27.968c8.832,0,16-7.152,16-16   C270.416,114.68,263.248,107.528,254.416,107.528z"
      ></path>
    </g>
    <g style="opacity: 0.07">
      <path
        style="fill: #010101"
        d="M511.936,253.256v61.92c0,17.76-14.24,32-32,32h-38.72l11.04-153.6L511.936,253.256z"
      ></path>
    </g>
    <path
      style="fill: #4b9fd8"
      d="M420.608,472.68H32c-17.68,0-32-14.32-32-32V196.792c0-17.664,14.32-32,32-32h388.608  c17.68,0,32,14.336,32,32v243.872C452.608,458.344,438.288,472.68,420.608,472.68z"
    ></path>
    <g>
      <path
        style="fill: #3a9bd6"
        d="M206.72,164.792c-99.84,75.68-170.08,172.64-206.4,279.04c-0.16-1.28-0.32-2.56-0.32-4v-243.84   c0-15.2,10.56-28,24.8-31.2L206.72,164.792L206.72,164.792z"
      ></path>
      <path
        style="fill: #3a9bd6"
        d="M245.904,472.68c99.84-75.68,170.08-172.64,206.4-279.04c0.16,1.28,0.32,2.56,0.32,4v243.84   c0,15.2-10.56,28-24.8,31.2H245.904z"
      ></path>
    </g>
    <circle style="fill: #fee218" cx="309.68" cy="251.768" r="38.976"></circle>
    <circle style="fill: #cd494c" cx="365.648" cy="251.768" r="38.976"></circle>
    <g>
      <path
        style="fill: #ffffff"
        d="M133.92,349.88H58.224c-8.832,0-16-7.152-16-16s7.168-16,16-16h75.696c8.832,0,16,7.152,16,16   C149.92,342.712,142.752,349.88,133.92,349.88z"
      ></path>
      <path
        style="fill: #ffffff"
        d="M201.536,267.768H58.224c-8.832,0-16-7.152-16-16s7.168-16,16-16h143.312c8.832,0,16,7.152,16,16   C217.536,260.616,210.368,267.768,201.536,267.768z"
      ></path>
    </g>
    <path
      style="fill: #8bd5ec"
      d="M105.088,431.992h-46.88c-8.832,0-16-7.152-16-16s7.168-16,16-16h46.88c8.832,0,16,7.152,16,16  C121.088,424.824,113.936,431.992,105.088,431.992z"
    ></path>
    <path
      style="fill: #daeff6"
      d="M201.536,431.992h-46.88c-8.832,0-16-7.152-16-16s7.168-16,16-16h46.88c8.832,0,16,7.152,16,16  C217.536,424.824,210.368,431.992,201.536,431.992z"
    ></path>
    <path
      style="fill: #8bd5ec"
      d="M297.968,431.992h-46.88c-8.832,0-16-7.152-16-16s7.168-16,16-16h46.88c8.832,0,16,7.152,16,16  C313.968,424.824,306.8,431.992,297.968,431.992z"
    ></path>
    <path
      style="fill: #daeff6"
      d="M394.4,431.992h-46.88c-8.832,0-16-7.152-16-16s7.168-16,16-16h46.88c8.832,0,16,7.152,16,16  C410.4,424.824,403.232,431.992,394.4,431.992z"
    ></path>
  </svg>
</template>