
import TheTaxi from "@/components/SVG/TheTaxi.vue";
import TheTaxiDriver from "@/components/SVG/TheTaxiDriver.vue";
import TheCreditCard from "@/components/SVG/TheCreditCard.vue";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: { TheTaxi, TheTaxiDriver, TheCreditCard },
  setup() {
    const { t } = useI18n();    

    return { t };
  },
});
