<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    xml:space="preserve"
    class="u-svg-content"
    viewBox="0 0 512 512"
    x="0px"
    y="0px"
    id="svg-ba14"
    style="enable-background: new 0 0 512 512; width:100%; height: 100%;"
  >
    <path
      style="fill: #ffb700"
      d="M442.589,73.164c-13.338-19.513-56.768-37.582-81.471-46.616  C324.573,13.181,283.61,2.776,256.76,0.04c-0.506-0.053-1.016-0.053-1.521,0c-26.85,2.736-67.813,13.142-104.358,26.508  c-24.702,9.034-68.133,27.104-81.471,46.616c-5.15,7.534-4.922,13.847-3.824,17.814c4.713,17.029,13.301,35.24,23.562,49.964  c12.58,18.051,27.436,30.822,42.96,36.933c0.875,0.345,1.807,0.521,2.747,0.521h242.291c0.94,0,1.872-0.177,2.747-0.521  c15.524-6.11,30.38-18.882,42.96-36.933c10.261-14.724,18.849-32.935,23.562-49.963C447.511,87.01,447.739,80.698,442.589,73.164z"
    ></path>
    <path
      style="fill: #bf612c"
      d="M399.499,136.292h-287c-4.143,0-7.5,3.357-7.5,7.5v122.503c0,4.143,3.357,7.5,7.5,7.5h287  c4.143,0,7.5-3.357,7.5-7.5V143.792C406.999,139.65,403.642,136.292,399.499,136.292z"
    ></path>
    <path
      style="fill: #f0eeee"
      d="M203.072,363.444h105.855c41.621,0,75.362,33.741,75.362,75.362V504.5H127.71v-65.694  C127.71,397.185,161.451,363.444,203.072,363.444z"
    ></path>
    <path
      style="fill: #ffb700"
      d="M308.927,355.944H203.072c-45.689,0-82.861,37.172-82.861,82.862V504.5c0,4.143,3.357,7.5,7.5,7.5  h256.578c4.143,0,7.5-3.357,7.5-7.5v-65.694C391.788,393.116,354.616,355.944,308.927,355.944z"
    ></path>
    <path
      style="fill: #d69400"
      d="M308.927,355.944h-25.932c45.689,0,82.861,37.172,82.861,82.862V504.5c0,4.143-3.357,7.5-7.5,7.5  h25.932c4.143,0,7.5-3.357,7.5-7.5v-65.694C391.788,393.116,354.616,355.944,308.927,355.944z"
    ></path>
    <path
      style="fill: #3d5868"
      d="M287.358,512l-12.044-69.067c-0.539-3.09-2.529-5.591-5.178-6.507  c-2.647-0.915-5.525-0.095-7.481,2.132l-6.656,7.575l-6.655-7.574c-1.956-2.229-4.834-3.049-7.481-2.132  c-2.648,0.916-4.639,3.417-5.178,6.507L224.641,512H287.358z"
    ></path>
    <path
      style="fill: #2a434f"
      d="M286.481,432.07c-0.129-2.142-1.17-4.127-2.859-5.451l-22.995-18.027  c-2.717-2.131-6.537-2.131-9.254,0l-22.995,18.027c-1.689,1.324-2.73,3.309-2.859,5.451s0.665,4.237,2.184,5.755l22.995,22.994  c1.464,1.464,3.384,2.196,5.303,2.196c1.919,0,3.839-0.732,5.303-2.196l22.995-22.994  C285.816,436.307,286.61,434.211,286.481,432.07z"
    ></path>
    <g>
      <path
        style="fill: #ffd100"
        d="M314.998,350.699l-19.34-9.878c-3.625-1.849-8.068-0.472-10.008,3.11l-36.248,66.993   c-1.739,3.216-0.907,7.217,1.97,9.472l37,29.007c1.333,1.046,2.966,1.598,4.627,1.598c0.591,0,1.185-0.069,1.77-0.212   c2.232-0.542,4.093-2.076,5.049-4.164l27.589-60.225C333.518,373.058,328.068,357.376,314.998,350.699z"
      ></path>
      <path
        style="fill: #ffd100"
        d="M262.596,410.924l-36.248-66.993c-1.938-3.581-6.383-4.961-10.008-3.11l-19.341,9.879   c-13.068,6.677-18.519,22.358-12.407,35.701l27.589,60.224c0.956,2.088,2.817,3.622,5.049,4.164   c0.585,0.143,1.179,0.212,1.77,0.212c1.661,0,3.294-0.552,4.627-1.598l37-29.007C263.503,418.141,264.335,414.14,262.596,410.924z"
      ></path>
    </g>
    <g>
      <path
        style="fill: #fa9e3e"
        d="M213.903,281.73v90.668c0,23.249,18.847,42.096,42.096,42.096l0,0   c23.249,0,42.096-18.847,42.096-42.096V281.73H213.903z"
      ></path>
      <path
        style="fill: #fa9e3e"
        d="M125.838,237.99h49.369v56.61h-49.369c-15.632,0-28.305-12.672-28.305-28.305   C97.533,250.663,110.206,237.99,125.838,237.99z"
      ></path>
      <path
        style="fill: #fa9e3e"
        d="M386.16,237.99h-49.369v56.61h49.369c15.632,0,28.305-12.672,28.305-28.305   C414.465,250.663,401.793,237.99,386.16,237.99z"
      ></path>
    </g>
    <path
      style="fill: #ffb56b"
      d="M368.114,244.676c0,61.919-50.195,112.115-112.115,112.115l0,0  c-61.919,0-112.115-50.195-112.115-112.115V143.792h224.229L368.114,244.676L368.114,244.676z"
    ></path>
    <path
      style="fill: #ef9245"
      d="M344.78,143.792v112.551c0,53.578-41.949,97.354-94.795,100.286c1.992,0.105,3.996,0.161,6.014,0.161  l0,0c61.919,0,112.115-50.195,112.115-112.115V143.792H344.78z"
    ></path>
    <path
      style="fill: #595866"
      d="M368.114,143.501H143.885c-4.143,0-7.5,3.357-7.5,7.5c0,25.824,21.009,46.833,46.833,46.833h145.563  c25.824,0,46.833-21.009,46.833-46.833C375.614,146.858,372.256,143.501,368.114,143.501z"
    ></path>
    <path
      style="fill: #484655"
      d="M201.676,474.02h-48.177c-4.143,0-7.5-3.357-7.5-7.5c0-4.142,3.357-7.5,7.5-7.5h48.177  c4.143,0,7.5,3.358,7.5,7.5C209.176,470.663,205.819,474.02,201.676,474.02z"
    ></path>
    <g>
      <path
        style="fill: #19303f"
        d="M255.999,295.485c-13.494,0-26.142-6.632-33.833-17.739c-2.358-3.405-1.509-8.077,1.896-10.436   c3.406-2.36,8.078-1.51,10.436,1.897c4.891,7.063,12.928,11.278,21.501,11.278c8.573,0,16.61-4.216,21.501-11.278   c2.358-3.404,7.029-4.257,10.435-1.897c3.405,2.358,4.255,7.03,1.897,10.436C282.141,288.853,269.493,295.485,255.999,295.485z"
      ></path>
      <path
        style="fill: #19303f"
        d="M310.322,239.962c-4.143,0-7.5-3.357-7.5-7.5v-2c0-4.143,3.357-7.5,7.5-7.5s7.5,3.357,7.5,7.5v2   C317.822,236.605,314.465,239.962,310.322,239.962z"
      ></path>
      <path
        style="fill: #19303f"
        d="M201.676,239.962c-4.143,0-7.5-3.357-7.5-7.5v-2c0-4.143,3.357-7.5,7.5-7.5s7.5,3.357,7.5,7.5v2   C209.176,236.605,205.819,239.962,201.676,239.962z"
      ></path>
    </g>
    <g>
      <rect
        x="104.999"
        y="120.22"
        style="fill: #ffffff"
        width="43.14"
        height="30.779"
      ></rect>
      <rect
        x="191.289"
        y="120.22"
        style="fill: #ffffff"
        width="43.14"
        height="30.779"
      ></rect>
      <rect
        x="277.569"
        y="120.22"
        style="fill: #ffffff"
        width="43.14"
        height="30.779"
      ></rect>
      <rect
        x="363.859"
        y="120.22"
        style="fill: #ffffff"
        width="43.14"
        height="30.779"
      ></rect>
      <rect
        x="148.139"
        y="89.2"
        style="fill: #ffffff"
        width="43.14"
        height="30.779"
      ></rect>
      <rect
        x="234.429"
        y="89.2"
        style="fill: #ffffff"
        width="43.14"
        height="30.779"
      ></rect>
      <rect
        x="320.709"
        y="89.2"
        style="fill: #ffffff"
        width="43.14"
        height="30.779"
      ></rect>
    </g>
    <g>
      <rect
        x="148.139"
        y="120.22"
        style="fill: #484655"
        width="43.14"
        height="30.779"
      ></rect>
      <rect
        x="234.429"
        y="120.22"
        style="fill: #484655"
        width="43.14"
        height="30.779"
      ></rect>
      <rect
        x="320.709"
        y="120.22"
        style="fill: #484655"
        width="43.14"
        height="30.779"
      ></rect>
      <path
        style="fill: #484655"
        d="M104.999,96.569v23.409h43.143v-30.78H112.37C108.299,89.198,104.999,92.498,104.999,96.569z"
      ></path>
      <rect
        x="191.289"
        y="89.2"
        style="fill: #484655"
        width="43.14"
        height="30.779"
      ></rect>
      <rect
        x="277.569"
        y="89.2"
        style="fill: #484655"
        width="43.14"
        height="30.779"
      ></rect>
      <path
        style="fill: #484655"
        d="M363.856,89.198v30.779h43.143V96.569c0-4.071-3.3-7.371-7.371-7.371H363.856z"
      ></path>
    </g>
  </svg>
</template>