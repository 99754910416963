<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    id="svg-5614"
    x="0px"
    y="0px"
    viewBox="0 0 511.991 511.991"
    style="enable-background: new 0 0 511.991 511.991; width:100%; height: 100%;"
    xml:space="preserve"
    class="u-svg-content"
  >
    <path
      style="fill: #434a54"
      d="M277.401,158.602h-21.344h-64.14h-21.328v-25.343c0-5.891,4.781-10.672,10.672-10.672h85.467  c5.891,0,10.672,4.781,10.672,10.672v25.343H277.401z"
    ></path>
    <path
      style="fill: #ffce54"
      d="M74.436,229.179c0,0,39.499-74.67,149.56-74.67c110.076,0,149.357,74.701,149.357,74.701  c127.981,0,127.981,0,127.981,117.287H53.343c-19.703,0.156-42.671-21.094-42.671-21.094v-74.896l21.343-21.328L74.436,229.179  L74.436,229.179z"
    ></path>
    <path
      style="fill: #4fc2e9"
      d="M366.227,218.508c-15.625-20.656-58.39-63.999-142.232-63.999  c-83.874,0-126.779,43.358-142.435,63.999H366.227z"
    ></path>
    <rect
      x="192.015"
      y="159.025"
      style="fill: #3bafda"
      width="21.328"
      height="59.48"
    ></rect>
    <path
      style="fill: #ffce54"
      d="M53.14,357.184c-23.171,0-47.062-21.5-49.702-23.938C1.25,331.215,0,328.371,0,325.402v-74.896  c0-2.828,1.125-5.547,3.125-7.547l21.343-21.328c2-2,4.719-3.125,7.547-3.125h36.468c4.969-7.5,15.484-21.312,32.859-35.046  c22.89-18.078,62.749-39.624,122.654-39.624c59.937,0,99.732,21.562,122.576,39.655c17.328,13.734,27.797,27.546,32.766,35.062  c62.701,0.016,94.732,0.516,113.201,17.453c19.452,17.844,19.452,48.804,19.452,110.49c0,5.906-4.766,10.688-10.656,10.688H53.14z   M21.343,320.497c7.297,5.844,21.296,15.344,31.796,15.344h0.125c0.016,0,0.047,0,0.078,0h437.32  c-0.062-46.998-0.859-73.396-12.531-84.099c-12.938-11.859-45.562-11.859-104.779-11.859c-3.953,0-7.594-2.203-9.438-5.703  c-0.078-0.141-9.531-17.531-31.406-34.608c-29.249-22.828-65.748-34.39-108.513-34.39c-102.451,0-139.763,68.295-140.138,68.983  c-1.844,3.484-5.469,5.672-9.422,5.672H36.421l-15.078,15.094V320.497z"
    ></path>
    <g>
      <path
        style="fill: #434a54"
        d="M117.341,282.749c-29.405,0-53.327,23.906-53.327,53.311c0,29.438,23.922,53.344,53.327,53.344   c29.406,0,53.327-23.906,53.327-53.344C170.668,306.655,146.747,282.749,117.341,282.749z"
      ></path>
      <path
        style="fill: #434a54"
        d="M394.664,282.749c-29.405,0-53.327,23.906-53.327,53.311c0,29.438,23.922,53.344,53.327,53.344   s53.327-23.906,53.327-53.344C447.991,306.655,424.069,282.749,394.664,282.749z"
      ></path>
    </g>
    <path
      style="fill: #f5f7fa"
      d="M106.67,336.059c0,5.906,4.781,10.688,10.671,10.688c5.891,0,10.672-4.781,10.672-10.688  c0-5.875-4.781-10.656-10.672-10.656C111.451,325.403,106.67,330.184,106.67,336.059z"
    ></path>
    <g>
      <path
        style="fill: #434a54"
        d="M170.731,261.257c-5.891,0-10.671,4.766-10.671,10.672c0,5.883,4.781,10.664,10.671,10.664   s10.672-4.781,10.672-10.664C181.403,266.022,176.621,261.257,170.731,261.257z"
      ></path>
      <path
        style="fill: #434a54"
        d="M192.058,282.593c-5.89,0-10.656,4.781-10.656,10.656c0,5.906,4.766,10.688,10.656,10.688   c5.891,0,10.672-4.781,10.672-10.688C202.73,287.374,197.949,282.593,192.058,282.593z"
      ></path>
      <path
        style="fill: #434a54"
        d="M213.402,261.257c-5.891,0-10.672,4.766-10.672,10.672c0,5.883,4.781,10.664,10.672,10.664   c5.89,0,10.656-4.781,10.656-10.664C224.058,266.022,219.292,261.257,213.402,261.257z"
      ></path>
      <path
        style="fill: #434a54"
        d="M234.73,282.593c-5.891,0-10.672,4.781-10.672,10.656c0,5.906,4.781,10.688,10.672,10.688   c5.89,0,10.671-4.781,10.671-10.688C245.401,287.374,240.62,282.593,234.73,282.593z"
      ></path>
      <path
        style="fill: #434a54"
        d="M256.057,261.257c-5.891,0-10.656,4.766-10.656,10.672c0,5.883,4.766,10.664,10.656,10.664   c5.89,0,10.672-4.781,10.672-10.664C266.729,266.022,261.948,261.257,256.057,261.257z"
      ></path>
      <path
        style="fill: #434a54"
        d="M277.401,282.593c-5.891,0-10.672,4.781-10.672,10.656c0,5.906,4.781,10.688,10.672,10.688   s10.656-4.781,10.656-10.688C288.057,287.374,283.292,282.593,277.401,282.593z"
      ></path>
      <path
        style="fill: #434a54"
        d="M298.728,261.257c-5.891,0-10.671,4.766-10.671,10.672c0,5.883,4.78,10.664,10.671,10.664   s10.672-4.781,10.672-10.664C309.4,266.022,304.619,261.257,298.728,261.257z"
      ></path>
      <path
        style="fill: #434a54"
        d="M320.056,282.593c-5.89,0-10.655,4.781-10.655,10.656c0,5.906,4.766,10.688,10.655,10.688   c5.891,0,10.672-4.781,10.672-10.688C330.727,287.374,325.946,282.593,320.056,282.593z"
      ></path>
      <path
        style="fill: #434a54"
        d="M341.399,261.257c-5.891,0-10.672,4.766-10.672,10.672c0,5.883,4.781,10.664,10.672,10.664   s10.656-4.781,10.656-10.664C352.056,266.022,347.29,261.257,341.399,261.257z"
      ></path>
    </g>
    <g>
      <path
        style="fill: #f5f7fa"
        d="M383.993,336.059c0,5.906,4.781,10.688,10.671,10.688c5.891,0,10.672-4.781,10.672-10.688   c0-5.875-4.781-10.656-10.672-10.656C388.774,325.403,383.993,330.184,383.993,336.059z"
      ></path>
      <path
        style="fill: #f5f7fa"
        d="M509.068,271.491c-1.312-8.141-3.234-15.062-6.016-20.984h-33.858   c-5.891,0-10.672,4.766-10.672,10.671c0,5.891,4.781,10.656,10.672,10.656h37.233C507.349,271.835,508.224,271.71,509.068,271.491z"
      ></path>
    </g>
    <path
      style="fill: #fc6e51"
      d="M32.015,218.508c-2.828,0-5.547,1.125-7.547,3.125L3.125,242.96c-2,2-3.125,4.719-3.125,7.547h32.015  c5.891,0,10.656-4.781,10.656-10.672v-21.328H32.015V218.508z"
    ></path>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>